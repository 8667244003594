$(document).ready(function () {
    initModalWatch();
});

function initModalWatch() {
    var modalEl = $('#modal-watch-animation');
    modalEl.on('show.bs.modal', function () {
        videoSRC = modalEl.find(".video-container").attr("data-theVideo");
        modalEl.find("iframe").attr('src', videoSRC);
    });
    modalEl.on('hide.bs.modal', function () {
        modalEl.find("iframe").removeAttr('src');
    });
    $('.watch-animation').on('click', function () {
        var modalEl = $('#modal-watch-animation');
        modalEl.modal('show');
    });
}